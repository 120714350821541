import {Order} from "../../../types/order";
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import dayjs from "dayjs";
import React from "react";

interface OrderReceiptPdf2Props {
    orders: Order[];
    global: any;
    totalPrice: number | string;
    totalKg: number;
    isGeneratingPDF: boolean;
}

export const OrderReceiptPdf2 = React.forwardRef<HTMLDivElement, OrderReceiptPdf2Props>((props, ref) => {
    return (
        <Box ref={ref} className={`${props.isGeneratingPDF ? "visible-content" : "hidden-content"}`}  sx={{ height: '243vh' }}>
            <Box sx={{mb: 12}}>
                <Grid
                    container
                    // justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        xs={6}
                    >
                        <Typography variant="body2">
                            Nr. Unik: <b>{props.global.companyDetails.businessNumber}</b>
                            <br/>
                            Nr. TVSH: <b>{props.global.companyDetails.taxNumber}</b>
                            {/*<br/>*/}
                            {/*<b>{props.global.companyDetails.name}</b>*/}
                            <br/>
                            Tel: <b>{props.global.companyDetails.phone}</b>
                            <br/>
                            Adresa: <b>{props.global.companyDetails.address}</b>
                        </Typography>
                    </Grid>
                    <Grid
                        xs={6}
                        sx={{display: 'flex', justifyContent: 'right'}}
                    >
                        <Box sx={{ width: 150, height: 150, overflow: 'hidden' }}>
                            <img
                                src="/assets/Lockup-Color.svg"
                                alt="Lockup"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Table sx={{border: '1px solid black'}}>
                <TableHead sx={{border: '1px solid black'}}>
                    <TableRow>
                        <TableCell sx={{py: 1}}>Id</TableCell>
                        <TableCell sx={{py: 1}}>Numri i porosis</TableCell>
                        <TableCell sx={{py: 1}}>Data e porosis</TableCell>
                        <TableCell sx={{py: 1}}>Klienti</TableCell>
                        <TableCell sx={{py: 1}}>Numri i leternjoftimit</TableCell>
                        <TableCell sx={{py: 1}}>Lloji</TableCell>
                        <TableCell sx={{py: 1}}>Sasia</TableCell>
                        <TableCell sx={{py: 1}}>Kilogrami</TableCell>
                        <TableCell sx={{py: 1}}>Çmimi</TableCell>
                        <TableCell sx={{py: 1}}>Totali</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.orders.map((order: any, index: number) => (
                        <TableRow sx={{ border: '1px solid black' }}>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order.idOrder}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{`${props.global.companyDetails.orderNumber}${order.idOrder}`}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order?.orderDate ? dayjs(order?.orderDate).format('DD.MM.YYYY') : ''}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{`${order.idClient.firstName}${order.idClient.lastName}`}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order.idClient.nationalId}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order?.type}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order?.quantity}</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order?.kilogram}kg</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order?.price}&euro;</TableCell>
                            <TableCell sx={{borderBottom: '1px solid black', py: 1}}>{order?.totalPrice}&euro;</TableCell>
                        </TableRow>
                    )
                    )}
                    {/*{Array.from({ length: 11 }).map((_, index) => (*/}
                    {/*    <TableRow key={index}>*/}
                    {/*        <TableCell sx={{ border: 0 }}></TableCell>*/}
                    {/*        <TableCell sx={{ border: 0 }}></TableCell>*/}
                    {/*        <TableCell sx={{ border: 0 }}></TableCell>*/}
                    {/*        <TableCell sx={{ border: 0 }}></TableCell>*/}
                    {/*        <TableCell sx={{ border: 0 }}></TableCell>*/}
                    {/*        <TableCell sx={{ border: 0 }}></TableCell>*/}
                    {/*    </TableRow>*/}
                    {/*))}*/}
                    <TableRow>
                        <TableCell
                            colSpan={9}
                            sx={{ borderBottom: 'none', borderTop: '1px solid black', py: 1 }}
                        />
                        <TableCell
                            align="right"
                            sx={{ borderBottom: 'none', borderTop: '1px solid black', py: 1 }}
                        >
                            <Typography sx={{fontSize: '14px'}}>
                                <span style={{fontWeight: 600, fontSize: '14px', marginRight: '1rem'}}>Totali:</span>
                                {props.totalPrice}&euro;
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            colSpan={9}
                            sx={{ borderBottom: 'none', py: 1 }}
                        />
                        <TableCell
                            align="right"
                            sx={{ borderBottom: 'none', pb: 1, pt: '0' }}
                        >
                            <Typography sx={{fontSize: '14px'}}>
                                <span style={{fontWeight: 600, fontSize: '14px', marginRight: '1rem'}}>Kg:</span>
                                {props.totalKg}kg
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 60,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    // mt: 10,
                }}
            >
                <Typography sx={{ fontWeight: 500, fontSize: '18px', mr: 9, fontFamily: 'Times New Roman' }}>Depoisti</Typography>
                <Typography sx={{ width: '10.5rem', borderBottom: '1px solid black', height: '7.2rem', mr: 2 }}></Typography>
            </Box>
        </ Box>
    )
});